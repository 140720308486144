var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-2",attrs:{"max-width":"900","tile":"","width":"100%"}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center pa-2",attrs:{"cols":"2","align-self":"center"}},[_c('v-img',{staticClass:"pa-0",attrs:{"contain":"","src":_vm.event.ciData.image_data.src,"max-width":_vm.$vuetify.breakpoint.smAndDown ? 75 : 100,"max-height":_vm.$vuetify.breakpoint.smAndDown ? 75 : 100}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-sheet',{staticClass:"d-flex flex-column align-start justify-space-between",attrs:{"height":"110"}},[_c('v-sheet',{staticClass:"d-flex flex-row align-center justify-space-between primary--text font-weight-bold text-subtitle-1",attrs:{"color":"transparent"}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('showDetails', {
                      type: 'ci',
                      event: { eventData: _vm.event }
                    })}}},on),[_c('v-icon',[_vm._v("mdi-layers-search")])],1)]}}])},[_c('span',[_vm._v("Catalog item details")])]),_c('ciHeading',{attrs:{"item":_vm.event.ciData,"showCategory":false}})],1),_c('v-sheet',{staticClass:"d-flex flex-row align-center primary--text font-weight-bold text-subtitle-1",attrs:{"color":"transparent"}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('showDetails', {
                      type: 'patron',
                      event: { eventData: _vm.event }
                    })}}},on),[_c('v-icon',[_vm._v("mdi-account-search")])],1)]}}])},[_c('span',[_vm._v("Patron Details")])]),_vm._v(" "+_vm._s(_vm.event.patronData.last_name || '')+", "+_vm._s(_vm.event.patronData.first_name || '')+" ")],1),_c('v-sheet',{staticClass:"d-flex flex-row align-center primary--text  text-subtitle-2",attrs:{"color":"transparent"}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('showDetails', {
                      type: 'event',
                      event: { eventData: _vm.event }
                    })}}},on),[_c('v-icon',[_vm._v("mdi-calendar-search")])],1)]}}])},[_c('span',[_vm._v("Event Details")])]),_vm._v(" "+_vm._s(_vm.eventTimeText())+" ")],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-sheet',{staticClass:"d-flex flex-column align-end justify-space-between",attrs:{"height":"110"}},[_c('event-status',{attrs:{"avatarSize":15,"eventData":_vm.event,"withLabel":false}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }